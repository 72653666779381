import TargetView from "./TargetView";
import InspectionTypeView from "./InspectionTypes/InspectionTypeView";
import VehicleTypeView from "./VehicleTypes/VehicleTypeView";
import Defaults from "./Defaults";
import React from "react";
import { useDefaultsData } from "../../hooks/useDefaultsData";
import { useSelector } from "react-redux";
import {getAppDefaultsData, getDataFresh, getVehicleMakeData} from "../../state/selectors";
import TransactionsSettings from "./Transactions/TransactionsSettings";
import CostCentreView from "./CostCentres/CostCentreView";
import QuickLinkView from "./QuickLinks/QuickLinkView";
import JobWorkshopView from "./JobWorkshop/JobWorkshopView";
import DepartmentView from "./Departments/DepartmentView";
import FitterView from "./Fitter/FitterView";
import VehicleTrackerType from "./VehicleTrackerType/VehicleTrackerTypeView";
import VehicleMake from "./VehicleDetails/Vehicle Make/VehicleMake";
import VehicleModel from "./VehicleDetails/VehicleModel/VehicleModel";
import {useVehicleMakeData} from "../../hooks/useVehicleMakeData";
import VehicleDetails from "./VehicleDetails/VehicleDetails";
import WorkTypeView from "./WorkType/WorkTypeView";

function Settings(props) {
  const dataFresh = useSelector(getDataFresh)
  const appDefaultsComplete = useDefaultsData({}, dataFresh);
  const appDefaults = useSelector(getAppDefaultsData);


  document.title = "Rentals Direct App";

  return (
    <div>
      <TargetView />
      <VehicleDetails {...props}/>
      <VehicleTypeView {...props} />
      <VehicleTrackerType {...props}/>
      <TransactionsSettings {...props} />
      <InspectionTypeView {...props} />
      <WorkTypeView {...props}/>
      <CostCentreView {...props} />
      <QuickLinkView {...props} />
      {appDefaultsComplete  && (
        <Defaults {...props} appDefaultsData={appDefaults} />
      )}
      <JobWorkshopView/>
      <DepartmentView/>
      <FitterView {...props}/>
    </div>
  );
}

export default Settings;
