import {Button, Form} from "react-bulma-components";
import React from "react";
import FAIcon from "../Icon/FAIcon";

export function Picker({name, label, value, onChange, options, blank, optionField, disabled, className, inlineEdit=false, onSave, editable, setEditable}){
  return (
      <Form.Field className={inlineEdit ? "has-addons "+className : className}>
           {label && <Form.Label>{label}</Form.Label>}
            <Form.Control>
                <Form.Select
                    name={name}
                    value={value ? value.id : -1}
                    onChange={onChange}
                    disabled={disabled}
                >
                  {blank && <option value={"BLANK"} key={-1}></option>}
                    {options.results.map(option => (
                        <option key={option.id} value={option.id}>{option[optionField]}</option>
                    ))}
                </Form.Select>
            </Form.Control>
            {inlineEdit && editable ? (
                <Form.Control>
                    <Button className={"small-row-white"} color="success" onClick={onSave}>
                        <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                    </Button>
                </Form.Control>
            ) : inlineEdit && (
                <Form.Control>
                    <Button
                        onClick={e => {
                            e.stopPropagation()
                            setEditable(!editable)}
                        }
                        className={"small-row-black"}
                    >
                        <FAIcon size="small" icon={["fas", "edit"]}/>
                    </Button>
                </Form.Control>
            )}
        </Form.Field>
    )
}