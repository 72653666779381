import React, {useState} from "react";
import {Box, Button, Columns} from "react-bulma-components";
import Collapsible from "react-collapsible";
import useInput from "../../../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../../../actions/dataActions";
import {getDataFresh} from "../../../../state/selectors";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import VehicleMakePicker from "../../../Vehicle/VehicleMakePicker";
import usePostVehicleModel from "../../../../hooks/usePostVehicleModel";


export default function NewVehicleModel({makes, makeObjects}){

    let [name, nameInput, setName] = useInput({
        type: "text",
        label: "Name"
    });
    let [mileageLimit, mileageLimitInput, setMileageLimit] = useInput({
        type: "number",
        label: "Mileage Limit"
    });
    let [make, setMake] = useState({id: 0})


    const clear = () =>{
        setName("");
        setMileageLimit(0);
        setMake({id: 0});
    }
    const [, postModel] = usePostVehicleModel({
        name: name,
        mileage_limit: mileageLimit,
        make: makeObjects[make.id]
    })
    const handleSubmit = () => {
        postModel().then(() => (
            clear()
        ))
    }

    return (
        <Box className="settings_type">
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Add Vehicle Model"
          >
              <Columns>
                  {nameInput}
                  {mileageLimitInput}
                  <Columns.Column>
                      <VehicleMakePicker
                        makes={makes}
                        vehicle={{make: make}}
                        onChange={e => setMake({id: e.target.value})}
                      />
                  </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                    <Button
                        color={"success"}
                        fullwidth
                        onClick={handleSubmit}
                    >
                        Add +
                    </Button>
                </Columns.Column>
              </Columns>
          </Collapsible>
        </Box>
    )
}