import React, {useRef, useState} from "react";
import axios from "axios";
import VehicleJobSection from "./VehicleJobSection";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {Heading, Table} from "react-bulma-components";
import { Form } from "react-bulma-components";
import shortid from "shortid";
import { Button } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import WorkSection from "./WorkSection";
import ContactSection from "../Contact/ContactSection";
import {
  createSetDataFreshAction,
  createSetJobAction,
  createSetJobFieldAction, createSetVehicleDataAction
} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getAppDefaultsData, getDataFresh, getFittersData, getJobData, getWorkTypeData} from "../../state/selectors";
import Validator from "./Validator";
import ReactToPrint from "react-to-print";
import PrintJob from "./PrintJob";
import PartSearchModal from "./PartSearchModal";
import {getPartCustomerCost, fixEmptyDates, fixEmptyDepartment, hasApprovedWork, getWorkTotals} from "../../utils";
import useSafeSave from "../../hooks/useSafeSave";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import TopSummary from "./TopSummary";
import ApprovalModal from "./ApprovalModal";
import Comment from "./Comment";
import WorkSearchModal from "./WorkSearchModal";
import PrintVehicleCheckInSheet from "./PrintVehicleCheckInSheet";
import FitterSelector from "./FitterSelector";
import KeyChangeModal from "./KeyChangeModal";
import DefectJobSection from "./Defects/DefectJobSection";
import WorkshopInstructions from "./WorkshopInstructions";
import ExistingItemModal from "./ExistingItemModal";

import ImageModal from "./ImageModal";
import PartEnquiryJobSection from "./Part Enquiry/PartEnquiryJobSection";
import ToQuoteModal from "./Part Enquiry/ToQuoteModal";
import ContactModal from "./ContactModal";
import VehicleMileageWarning from "./VehicleMileageWarning";

function JobForm(props) {

  const printJobSheetRef = useRef();
  const printVehicleCheckInRef = useRef();

  const dateFields = ["book_in_date", "workshop_date", "finish_date"];
  const [lastChange, setLastChange] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [enquiryToQuoteModalOpen, setEnquiryToQuoteModalOpen] = useState(false);
  const [instructionModalOpen, setInstructionModalOpen] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [keyModal, setKeyModal] = useState(false);
  const [similarModal, setSimilarModal] = useState(false);
  const [associatedItems, setAssociatedItems] = useState([]);
  const [mileageWarningModal, setMileageWarningModal] = useState(false);

  const dispatch = useDispatch();
  const fitters = useSelector(getFittersData)
  let job = useSelector(getJobData);
  let workTypes = useSelector(getWorkTypeData);
  let appDefaults = useSelector(getAppDefaultsData);
  let dataFresh = useSelector(getDataFresh);
  const invoiced = job.customer_invoice_number && job.customer_invoice_number;

  const [partsModalState, setPartsModalState] = useState({
    modalOpen: false,
    currentSearch: "",
    searchText: "",
    partIndex: 0,
    workIndex: 0
  });

  const [workModalState, setWorkModalState] = useState({
    modalOpen: false,
    currentSearch: "",
    searchText: "",
    workIndex: 0
  });

  const addWorkItem = () => {
    triggerChange({
      work_items: job.work_items.concat({
        key: shortid.generate(),
        chargeable: !!job.customer2,
        labour_cost: 0,
        estimated_labour_cost: 0,
        customer_labour_cost: 0,
        estimated_customer_labour_cost: 0,
        labour_hours: 0,
        estimated_labour_hours: 0,
        parts: [],
        quote: job.quote,
        added_after_quote: true,
        job: job.id || undefined,
        complete: false
      })
    });
  };

  const addComment = (e) => {
    e.preventDefault();
    triggerChange({
      comments_list: job.comments_list.concat({
        key: shortid.generate(),
        text: "",
        job: job.id || undefined
      })
    })
  };

  const checkNotUpdated = () => {
    if (job.id) {
      return axios
        .get(props.endpoint + "jobs/" + job.id)
        .then(
          result => {
            return (new Date(result.data.last_saved).getTime() -
              new Date(job.last_saved).getTime()) /
            1000 <
            1}
        ).catch(err => {
          NotificationManager.error("An error occurred!");
        });
    }
    return new Promise((res, rej) => res(true));
  };

  const searchSelectPart = (part, partIndex, workIndex) => {
    let newWorkItem = job.work_items[workIndex];
    let customer = newWorkItem.chargeable ? job.customer2 : job.customer;

    let newPart = {
      name: part.name,
      part_number: part.part_number,
      supplier: part.supplier,
      cost: part.cost,
      estimated_cost: 0,
      customer_cost: getPartCustomerCost(part, customer, appDefaults),
      estimated_customer_cost: 0,
      created_from_lookup: part.id
    };

    newWorkItem.parts[partIndex] = {
      ...newWorkItem.parts[partIndex],
      ...newPart
    };

    updateWork(newWorkItem, workIndex);
    setPartsModalState({...partsModalState, modalOpen: false});
  };
  const partLookupToParts = (lookup) => {
    let part = {
      part_number: lookup.part_number,
      cost: lookup.cost,
      supplier: lookup.supplier,
      name: lookup.name,
    }

    part.customer_cost = getPartCustomerCost(part, job.customer2 ? job.customer2 : job.customer, appDefaults);


    return part;
  }

  const filterInstructions = (instruction) => {
        return instruction.on_completion
  }
  const searchSelectWork = (work, workIndex) => {
    let parts = [];
    work.part_work_lookup.map(part => {
      parts.push(partLookupToParts(part))
    })

    let newWorkItem = {
      key: shortid.generate(),
      description: work.description,
      chargeable: !!job.customer2,
      labour_cost: work.labour_cost,
      estimated_labour_cost: 0,
      customer_labour_cost: work.customer_labour_cost,
      estimated_customer_labour_cost: 0,
      labour_hours: work.labour_hours,
      estimated_labour_hours: 0,
      parts: parts,
      quote: job.quote,
      added_after_quote: true,
      job: job.id || undefined,
      cost_override: true
    };
    triggerChange({
      work_items: job.work_items.concat(newWorkItem)
    });
    setWorkModalState({...workModalState, modalOpen: false});
  };

  const onSearchPartClick = (part, partIndex, workIndex) => {
    const searchText = [
      job.vehicle ? job.vehicle.make ? job.vehicle.make.name : "" : "",
      job.vehicle.type ? job.vehicle.type.name : "",
      part.name
    ].join(" ");

    setPartsModalState({
      modalOpen: true,
      currentSearch: searchText,
      workIndex,
      partIndex,
      searchText
    });
  };
  const triggerChange = (data, name) => {
    makeDirty();
    setLastChange(new Date());
    dispatch(createSetJobFieldAction(data));
  };

  const onDefectChange = e =>{
    triggerChange({[e.target.name]: e.target.value});
  }

  const updateWork = (work, index) => {
    let newWorkItems = [...job.work_items];
    newWorkItems.splice(index, 1, work);
    triggerChange({work_items: newWorkItems});
  };

  const removeWorkItem = index => {
    let newWorkItems = [...job.work_items];
    newWorkItems.splice(index, 1);
    triggerChange({work_items: newWorkItems});
  };

  const updateComment = (comment, index) => {
    let newComments = [...job.comments_list];
    newComments.splice(index, 1, comment);
    triggerChange({comments_list: newComments});
  };

  const removeComment = index => {
    let newComments = [...job.comments_list];
    newComments.splice(index, 1);
    triggerChange({comments_list: newComments});
  };

  const handleChange = e => {
    const {name, value} = e.target;
    if(name === 'mileage'){
      triggerChange({[name]: value}, name);
    }
    else{
      triggerChange({[name]: value});
    }
  };

  const handleHistoryChange = e => {
    const {name, value} = e.target;
    triggerChange({['history']: value});
  }

  const onCheck = name => {
    triggerChange({[name]: !job[name]});
  };

  const handleUpdateHireCustomer = e => {
    triggerChange({customer2: e});
    if (!e) {
      let newWorkItems = job.work_items.map(item => {
        return {...item, chargeable: false};
      });
      triggerChange({work_items: newWorkItems});
    }
  };
  const onKeyClear = (spare) => {
      const url = "/api/vehicles/clearkey";
      let conf = {
          data: {'vehicle': job.vehicle, 'spare': spare},
          url: url,
          method: "put"
      };
      return axios(conf)
          .then((res) => {
            dispatch(createSetDataFreshAction(dataFresh +1))
          }).catch(err => {
            NotificationManager.error("There was an error\n"+err)
          });
  };

  const handleStatus = status => {
     return safeSave().then(statusChange(status))
      .catch(reason => {
        NotificationManager.error("Unable to change job status");
      });
  }

  const handleApprove = e => {
    if (job.mileage) {
      safeSave()
        .then(approve)
        .catch(reason => {
          NotificationManager.error("Unable to approve job");
        });
    } else {
      NotificationManager.error("You must specify a mileage before approving!");
    }
  };

  const handleSave = e => {
    e.preventDefault();
    safeSave().then().catch(err => {NotificationManager.error("An error occurred!")});
  };

  const vehicleSave = () => {
    let vehicleToSave = job.vehicle;
    const endpoint = props.endpoint + "/vehicles/"+vehicleToSave.id;

    const conf = {
      method: "put",
      data: vehicleToSave,
      url: endpoint
    };
    return axios(conf).then(resp => {
      dispatch(createSetVehicleDataAction(resp.data));
    }).catch(err => {NotificationManager.error("An error occurred!")});
  }


  const statusChange = (status) => {
    let newJob = job;
    const url = props.endpoint + "jobs/returned/" + job.id;
    newJob.status = status;

    axios.put(url, newJob).then(response => {
      dispatch(createSetJobAction(response.data))
    }).catch(reason => {
      NotificationManager.error("An error occurred!")
    });
  }

  const approve = () => {
    const endpoint = props.endpoint + "jobs/approve/" + job.id;
    const conf = {
      method: "post",
      url: endpoint
    };
    axios(conf).then(response => dispatch(createSetJobAction(response.data)));
  };
  const save = () => {
    let jobToSave = fixEmptyDates(job, dateFields);
    jobToSave = fixEmptyDepartment(job);
    mileageWarning();
    const endpoint = jobToSave.id
      ? props.endpoint + "jobs/" + jobToSave.id
      : props.endpoint + "createjob";
    const method = jobToSave.id ? "put" : "post";

    const conf = {
      method: method,
      data: jobToSave,
      url: endpoint
    };
    return axios(conf).then(resp => {
      dispatch(createSetJobAction(resp.data));
      if (!jobToSave.id) {
        props.history.push("/editjob/" + resp.data.id);
      }
    }).catch(err => {NotificationManager.error("An error occurred!")});
  };

  const [makeDirty, safeSave] = useSafeSave(
    save,
    lastChange,
      (!job.is_defect && !job.is_enquiry) ? (() => Validator(job)) : null,
    checkNotUpdated
  );

  const handleDelete = () => {
    const endpoint = props.endpoint + "jobs/" + job.id;
    const conf = {method: "delete", url: endpoint};
    axios(conf).then(response => props.history.push("/jobs"));
  };

  const defectToQuote = () => {
    if(job.customer || job.customer2) {
      const conf = {
        data: job,
        method: "post",
        url: props.endpoint + "defectquote"
      }
      axios(conf).then((res) => {
        if (res.data.id) {
          props.history.push("/editquote/" + res.data.id);
        }
      }).catch((err) => {
        NotificationManager.error("An error occurred!")
      });
    }
    else {
      NotificationManager.error("A customer is required");
    }
  };

  const getAllJobsQuotes = (vehicle) => {
    axios
      .all([
        axios.get(props.endpoint + `quotes?vehicle=${vehicle.id}&ready_for_customer_invoice=false`),
        axios.get(
          props.endpoint + `jobs?vehicle=${vehicle.id}&view=not_finished&ws=all`
        )
      ])
      .then(
        axios.spread((estimateResp, jobResp) => {
          if (
              jobResp.data.results.length ||
              estimateResp.data.results.length
          ) {
            setAssociatedItems(
                jobResp.data.results.filter((j) => j.id !== job.id)
                    .map(jobData => {
                      return {...jobData, itemType: "Job"};
                    })
                    .concat(
                        estimateResp.data.results.map(estimateData => {
                          return {...estimateData, itemType: "Quote"};
                        })
                    )
            );
            setSimilarModal(true);
          }
        })
      )
  }

  const handleContinue = () => {
    setSimilarModal(false);
  };

  const mileageWarning = () => {
      const warningPoint = job.vehicle.model ? job.vehicle.model.mileage_limit : null;
      if(warningPoint && job.mileage >= (warningPoint)){
        setMileageWarningModal(true);
      }
    }

  if (props.loaded) {
    const workTypeObjects = workTypes.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {});

    const departmentObjects = props.departments.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
    }, {});
    if(!job.department){
      triggerChange({department: Object.values(departmentObjects)[0]})
    }
    const onDepartmentsChange = (e) => {
      const data = departmentObjects[e.target.value];
      triggerChange({department: data});
    };
    document.title = "Job - " + (job.vehicle && job.vehicle.registration ? job.vehicle.registration : "New");
    return (
      <div>
        <TopSummary
          quote={job.quote}
          job={job}
          customer_label={job.customer ? job.customer.display_name : "Customer"}
          customer2_label={
            job.customer2 ? job.customer2.display_name : "Customer 2"
          }
          itemType="Job"
          onSave={handleSave}
          handleChange={handleChange}
        ></TopSummary>
        <Columns>
          <Columns.Column>
            <VehicleJobSection
              handleVehicleChange={vehicle => triggerChange({vehicle})}
              customerVehicle={job.customer_vehicle}
              vehicleUpdate={vehicle => triggerChange({vehicle})}
              endpoint={props.endpoint}
              vehicle={job.vehicle ? job.vehicle : {}}
              history={props.history}
              type={"Job Card"}
              disabled={job.id && (job.is_defect || job.is_enquiry) }
              keys={props.keys}
              keyFilterField={"WORKSHOP"}
              keyFilter={true}
              onKeyClear={onKeyClear}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <DefectJobSection
              job={job}
              onChange={onDefectChange}
              dataFresh={dataFresh}
              disabled={!job.is_defect}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <PartEnquiryJobSection
              job={job}
              disabled={!job.is_enquiry}
              onChange={onDefectChange}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <ContactSection
              title="Workshop"
              contactUpdate={workshop => triggerChange({workshop})}
              endpoint={props.endpoint + "contacts"}
              contact={job.workshop || {}}
              type="SP"
              disabled={job.is_defect || job.is_enquiry}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <ContactSection
              title="Customer 1"
              contactUpdate={customer => triggerChange({customer})}
              endpoint={props.endpoint + "contacts"}
              contact={job.customer || {}}
              type="CC"
              disabled={!!job.quote || job.is_defect || job.is_enquiry}
              displayWorkshopInstructions
            />
          </Columns.Column>
          <Columns.Column>
            <ContactSection
              title="Customer 2"
              contactUpdate={handleUpdateHireCustomer}
              endpoint={props.endpoint + "contacts"}
              contact={job.customer2 || {}}
              type="CC"
              disabled={!!job.quote || job.is_defect || job.is_enquiry}
              displayWorkshopInstructions
            />
          </Columns.Column>
        </Columns>

        <Box>
          <h1 className="title">Job Info</h1>
          <form>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Department</Form.Label>
                  <Form.Control>
                    <Form.Select
                        name='department'
                        value={job.department ? job.department.id : 1}
                        onChange={onDepartmentsChange}
                        disabled={job.is_defect}
                    >
                      {props.departments.results.map(item => (
                        <option key={item.name} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Label>Fitter</Form.Label>
                <Form.Control>
                  <FitterSelector
                    fitters={fitters}
                    value={job.fitter ? job.fitter.id : null}
                    triggerChange={triggerChange}
                    editable={!job.is_defect}
                  />
                </Form.Control>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Wash Needed</Form.Label>
                    <Form.Checkbox
                      type="checkbox"
                      name="wash_needed"
                      onChange={() => {
                          onCheck("wash_needed");
                        }
                      }
                      checked={job.wash_needed}
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Wash Complete</Form.Label>
                    <Form.Checkbox
                      type="checkbox"
                      name="wash_needed"
                      onChange={() => {
                        onCheck("wash_complete");
                      }}
                      checked={job.wash_complete}
                      disabled={!job.wash_needed || job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Job Description</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="job_description"
                      onChange={handleChange}
                      value={job.job_description}
                      required
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Workshop Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="workshop_date"
                      onChange={handleChange}
                      value={job.workshop_date || ""}
                      required
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Booked In Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="book_in_date"
                      onChange={handleChange}
                      value={job.book_in_date || ""}
                      required
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Finish Date</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="date"
                      name="finish_date"
                      onChange={handleChange}
                      value={job.finish_date || ""}
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Drop Off ETA</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="time"
                      name="drop_off_eta"
                      onChange={handleChange}
                      value={job.drop_off_eta || ""}
                      required
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Collection ETA</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="time"
                      name="collection_eta"
                      onChange={handleChange}
                      value={job.collection_eta || ""}
                      required
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Job Mileage</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="mileage"
                      onChange={handleChange}
                      value={String(job.mileage)}
                      disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Expected Mileage</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="expected_mileage"
                      onChange={handleChange}
                      value={String(job.expected_mileage)}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Customer 1 Invoice Number</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="customer_invoice_number"
                      data-testid={"customer1-invoice-number"}
                      onChange={handleChange}
                      value={job.customer_invoice_number}
                      disabled={job.is_defect || hasApprovedWork(job, "invoice_approved")}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Customer 2 Invoice Number</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="customer2_invoice_number"
                      data-testid={"customer2-invoice-number"}
                      onChange={handleChange}
                      value={job.customer2_invoice_number}
                      disabled={job.is_defect || hasApprovedWork(job, "invoice_approved")}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Workshop Invoice Number</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      name="workshop_invoice_number"
                      data-testid={"workshop-invoice-number"}
                      onChange={handleChange}
                      value={job.workshop_invoice_number}
                      disabled={job.is_defect || hasApprovedWork(job, "po_approved")}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Table size={"fullwidth"}>
                  <thead>
                  <tr>
                    <th>Comment</th>
                    <th>Remove</th>
                  </tr>
                  </thead>
                  <tbody>
                    {job.comments_list.map((value, index) => (
                      <Comment
                        job={job.id}
                        quote={job.quote}
                        key={value.id || value.key}
                        index={index}
                        comment={value}
                        updateComments={updateComment}
                        removeComment={removeComment}
                        disabled={job.is_defect}
                      />
                    ))}
                  </tbody>
                </Table>
                <Columns>
                  <Columns.Column>
                    <Button
                      tabIndex={-1}
                      type="button"

                      color="info"
                      onClick={addComment}
                      disabled={job.is_defect}
                    >
                      Add Comment
                    </Button>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
            <Columns>
               <Columns.Column>
                <Form.Field>
                  <Form.Label>History</Form.Label>
                  <Form.Control>
                    <Form.Textarea
                        onChange={handleHistoryChange}
                        value={job.history}
                        disabled={job.is_defect}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </form>
        </Box>
        <Box>
          <h1 className="title">Work</h1>
          <Box>
            {/*<WorkTotalsSection*/}
            {/*  items={job.work_items}*/}
            {/*  quote={job.quote}*/}
            {/*  customer_name={job.customer ? job.customer.display_name : ""}*/}
            {/*  customer2_name={*/}
            {/*    job.customer2 ? job.customer2.display_name : ""*/}
            {/*  }*/}
            {/*/>*/}
            <Columns>
              <Columns.Column>
                {job.work_items.map((value, index) => (
                  <WorkSection
                    job={job.id}
                    quote={job.quote}
                    workTypes={workTypes}
                    workTypeObjects={workTypeObjects}
                    key={value.id || value.key}
                    customer={job.customer}
                    supplier={job.workshop}
                    hireCustomer={job.customer2}
                    vehicle={job.vehicle}
                    index={index}
                    workItem={value}
                    update_work={updateWork}
                    remove_work_item={removeWorkItem}
                    onSearchPartClick={onSearchPartClick}
                    invoiced={invoiced}
                    lcv_job={job.department === "LCV Centre"}
                    appDefaults={appDefaults}
                  />
                ))}
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Button
                  tabIndex={-1}
                  id="add-workitem"
                  color="info"
                  className="is-pulled-left"
                  onClick={addWorkItem}
                  disabled={!!invoiced || job.is_defect || job.is_enquiry}
                >
                  Add Work Item
                </Button>
                <Button
                  tabIndex={-1}
                  color="info"
                  style={{marginLeft: '10px'}}
                  onClick={() => {
                    setWorkModalState({...workModalState, modalOpen: true})
                  }}
                  disabled={!!invoiced || job.is_defect || job.is_enquiry}
                >
                  Search Work Lookups
                </Button>
              </Columns.Column>
            </Columns>
          </Box>
        </Box>
        <Box>
          <Form.Field>
            <div className="control">
              <Button onClick={handleSave} fullwidth color="info">
                Save Job
              </Button>
            </div>
          </Form.Field>
          <Form.Field>
            <div className="control">
              <Button onClick={() => setImageModalOpen(true)} fullwidth color="info">
                Show Attached Images
              </Button>
            </div>
          </Form.Field>
          {job.id && !job.is_defect && !job.is_enquiry &&(
              <>
            <Form.Field>
              <ReactToPrint
                trigger={() => (
                  <Button color="warning" fullwidth>
                    Print Workshop Job Sheet
                  </Button>
                )}
                content={() => printJobSheetRef.current}
                bodyClass="print-landscape"
                pageStyle="@page {
                  size: A4 landscape;
                }"
              />
            </Form.Field>
            <Form.Field>
              <ReactToPrint
                trigger={() => (
                  <Button color="warning" fullwidth>
                    Print Vehicle Check-in Sheet
                  </Button>
                )}
                content={() => printVehicleCheckInRef.current}
                bodyClass="print-portrait"
                pageStyle="@page {
                  size: A4 portrait;
                }"
              />
            </Form.Field>
            </>
          )}
          {
            (job.is_defect || job.is_enquiry) &&
              <Form.Field>
              <div className="control" >
                <Button
                  onClick={() => {
                    triggerChange({is_defect: false, is_enquiry: false})
                  }}
                  fullwidth
                  color="success"
                  id={"defect-to-job"}
                  data-testid="defect-to-job"
                >
                  Turn Into Job
                </Button>
              </div>
            </Form.Field>
          }
          {job.is_defect &&
            <Form.Field>
                <div className="control" >
                  <Button
                    onClick={() => {
                      setContactModalOpen(true);
                    }}
                    fullwidth
                    color="success"
                    id={"defect-to-quote"}
                    data-testid="defect-to-quote"
                  >
                    Turn Defect Into Quote
                  </Button>
                </div>
            </Form.Field>
          }
          {job.is_enquiry &&
            <Form.Field>
                <div className="control" >
                  <Button
                    onClick={() => {
                      setEnquiryToQuoteModalOpen(true);
                    }}
                    fullwidth
                    color="success"
                    id={"enquiry-to-quote"}
                    data-testid="enquiry-to-quote"
                  >
                    Turn Enquiry Into Quote
                  </Button>
                </div>
            </Form.Field>
          }
          {
          job.id && !job.approved && !job.is_defect && !job.is_enquiry && job.status === "IN_PROGRESS" &&
            <Form.Field>
              <div className="control" >
                <Button
                  onClick={() => {
                    handleStatus("RETURNED_FROM_WORKSHOP");
                  }}
                  fullwidth
                  color="success"
                  id={"Returned"}
                  data-testid="Returned"
                >
                  Returned From Workshop
                </Button>
              </div>
            </Form.Field>
          }
          {job.id && !job.approved && (job.status === "READY_FOR_COLLECTION" || job.status === "COLLECTED") && (
            <Form.Field>
              <div className="control">
                <Button
                  onClick={() => {
                    setApproveModalOpen(true);
                    getAllJobsQuotes(job.vehicle);
                    if(associatedItems.length > 0) {
                      setSimilarModal(true);
                    }
                  }}
                  fullwidth
                  color="success"
                >
                  Approve Job
                </Button>
              </div>
            </Form.Field>
          )}
          {job.id && job.status === "RETURNED_FROM_WORKSHOP" && (
            <Form.Field>
              <div className="control">
                <Button
                  onClick={() => {
                    handleStatus("READY_FOR_COLLECTION").then(() =>{
                      if(job.customer && job.customer.workshop_instructions.filter((instruction) => filterInstructions(instruction)).length > 0 ||
                          job.customer2 && job.customer2.workshop_instructions.filter((instruction) => filterInstructions(instruction)).length > 0){
                        setInstructionModalOpen(true);
                      }
                      setKeyModal(true);
                    })
                  }}
                  fullwidth
                  color="success"
                >
                  Ready For Collection
                </Button>
              </div>
            </Form.Field>
          )}
          {job.id && job.status === "READY_FOR_COLLECTION" && (
            <Form.Field>
              <div className="control">
                <Button
                  onClick={() => {
                    handleStatus("COLLECTED");
                  }}
                  fullwidth
                  color="success"
                >
                  Mark As Collected
                </Button>
              </div>
            </Form.Field>
          )}
          {job.id && (
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={e => {
                    e.preventDefault();
                    setDeleteModalOpen(true);
                  }}
                  fullwidth
                  color="danger"
                >
                  Delete Job
                </Button>
              </Form.Control>
            </Form.Field>
          )}
        </Box>
        <PartSearchModal
          {...partsModalState}
          setModalState={setPartsModalState}
          selectPart={searchSelectPart}
        />
        <WorkSearchModal
          {...workModalState}
          setModalState={setWorkModalState}
          selectWork={searchSelectWork}
          supplier={job.workshop}
        />
        <DeleteWarningModal
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDelete={handleDelete}
        />
        <ApprovalModal
          open={approveModalOpen}
          setOpen={setApproveModalOpen}
          handleChange={handleChange}
          job={job}
          workItems={job.work_items}
          onApprove={handleApprove}
          isJob={true}
        />
        <ExistingItemModal
          open={similarModal}
          setOpen={setSimilarModal}
          items={associatedItems}
          onContinue={handleContinue}
          history={props.history}
          type={"Job Card"}
          vehicle={job.vehicle}
          hideType={true}
        />
        <KeyChangeModal
          open={keyModal}
          setOpen={setKeyModal}
          keys={props.keys}
          triggerChange={triggerChange}
          vehicle={job.vehicle}
          save={vehicleSave}
          selectedKey={job.vehicle ? job.vehicle.key : {}}
          onClear={onKeyClear}
        />
        <ImageModal
          job={job}
          open={imageModalOpen}
          setOpen={setImageModalOpen}
          dataFresh={dataFresh}
          type={'job'}
        />
        <ContactModal
          job={job}
          open={contactModalOpen}
          setOpen={setContactModalOpen}
          endpoint={props.endpoint}
          dataFresh={dataFresh}
          triggerChange={triggerChange}
          handleSave={defectToQuote}
        />
        <WorkshopInstructions
          contact1={job.customer}
          contact2={job.customer2 ? job.customer2 : {}}
          open={instructionModalOpen}
          setOpen={setInstructionModalOpen}
          filterInstructions={filterInstructions}
        />
        <ToQuoteModal
          open={enquiryToQuoteModalOpen}
          setOpen={setEnquiryToQuoteModalOpen}
          job={job}
          endpoint={props.endpoint}
          history={props.history}
        />
        <VehicleMileageWarning
          open={mileageWarningModal}
          setOpen={setMileageWarningModal}
          job={job}
        />
        <div style={{display: "none"}}>
          <div>
            <PrintJob data={job} ref={printJobSheetRef}/>
            <PrintVehicleCheckInSheet data={job} ref={printVehicleCheckInRef}/>
          </div>
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

export default JobForm;
