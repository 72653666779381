import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Button, Form} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";


export default function WorkTypeRow({workTypeData, index, dataFresh, onUpdate}){
    const dispatch = useDispatch();
    const [workType, setWorkType] = useState(workTypeData);
    const [editable, setEditable] = useState(false);

    const onEdit = e => {
        e.stopPropagation();
        setEditable(!editable);
    };
    const handleDelete = e => {
        const conf = {
            method: "delete",
            url: "api/worktype/"+workType.id
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            if(err){
                NotificationManager.error("There was an error");
            }
        })
    }
    const onChange = (e) => {
        let newWorkType = {...workType};
        newWorkType[e.target.name] = e.target.value;
        setWorkType(newWorkType);
        onUpdate(index, newWorkType);
    }

    const onCheck = (e) => {
        let toUpdate = {
            target: {
                name : e.target.name,
                value: !workType[e.target.name]
            }
        }
        onChange(toUpdate);
    }
    const submitUpdate = (e) => {
        e.stopPropagation();
        const conf = {
            method: "put",
            data: workType,
            url: "api/worktype/"+workType.id
        }
        axios(conf).then(res => {
           setEditable(false);
           dispatch(createSetDataFreshAction(dataFresh + 1))
        })
    }


    return (
        <tr key={workTypeData.id}>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"text"}
                            name={"name"}
                            value={workType.name}
                            disabled={!editable}
                            onChange={onChange}
                        />
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Form.Field onClick={e => e.stopPropagation()}>
                    <Form.Control>
                        <Form.Checkbox
                            name={"warranty_work"}
                            checked={workType.warranty_work}
                            disabled={!editable}
                            onChange={onCheck}
                        />
                    </Form.Control>
                </Form.Field>
            </td>
            <td>
                <Form.Field onClick={e => e.stopPropagation()}>
                    <Form.Control>
                        <Form.Checkbox
                            name={"approval_needed"}
                            checked={workType.approval_needed}
                            disabled={!editable}
                            onChange={onCheck}
                        />
                    </Form.Control>
                </Form.Field>
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={handleDelete}
                >
                    Delete
                </Button>
            </td>
        </tr>
    )


}