import React, {useState} from "react";
import {useVehicleModelData} from "../../../../hooks/useVehicleModelData";
import {getDataFresh, getVehicleMakeData, getVehicleModelData} from "../../../../state/selectors";
import {useDispatch, useSelector} from "react-redux";
import {Box, Heading, Pagination, Table} from "react-bulma-components";
import NewVehicleModel from "./NewVehicleModel";
import VehicleModelRow from "./VehicleModelRow";
import Spinner from "react-spinner-material";
import {createSetVehicleModelDataAction} from "../../../../actions/dataActions";
import SearchBar from "../../../Utils/SearchBar";
import Collapsible from "react-collapsible";


export default function VehicleModel(props){
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    let params = { page };
    const [currentSearch, setCurrentSearch] = useState();
    const [searchFresh, setSearchFresh] = useState(0);
    const [searchText, setSearchText] = useState();

    if (currentSearch) {
      params.search = currentSearch;
    }
    if (searchFresh) {
      params.searchFresh = searchFresh
    }

    const loading = useVehicleModelData(params, props.dataFresh);
    const data = useSelector(getVehicleModelData);


    let vehicleMakes = useSelector(getVehicleMakeData)
    const vehicleMakeObjects = vehicleMakes.results.reduce((obj, item) => {
        return {
          ...obj,
          [item["id"]]: item
        };
    }, {});

    if (!loading) {
        return (
          <Box>
            <Spinner
              className="spinner-centre"
              size={120}
              spinnerColor={"#3273dc"}
              spinnerWidth={2}
            />
          </Box>
        );
    }
    const handleSearch = () => {
        setPage(1);
        setCurrentSearch(searchText);
        setSearchFresh(searchFresh+1);
    };

    const clear = () => {
        setSearchText("");
        setCurrentSearch("");
        setPage(1);
    };

    const onVehicleModelUpdate = (index, newVehicleMake) => {
        let newState = data;
        newState.results[index] = newVehicleMake;
        dispatch(createSetVehicleModelDataAction(newState))
    }

    return (
        <div>
            <NewVehicleModel makeObjects={vehicleMakeObjects} makes={vehicleMakes}/>
            <Box>
                <Collapsible
                    className="title"
                    triggerOpenedClassName="title"
                    trigger="Vehicle Models"
                >
                    <Heading size={6}>Note: Changing these will affect all vehicles of that model</Heading>
                    <SearchBar handleSearch={handleSearch} searchText={searchText} setSearchText={setSearchText} clear={clear}/>
                    <Table
                        size={"fullwidth"}
                    >
                        <thead>
                            <tr>
                                <th>Model</th>
                                <th>Make</th>
                                <th>Mileage Limit</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.results.map((model, index) => (
                              <VehicleModelRow
                                modelData={model}
                                index={index}
                                onUpdate={onVehicleModelUpdate}
                                makes={vehicleMakes}
                                makeObjects={vehicleMakeObjects}
                                dataFresh={props.dataFresh}
                                key={model.id}
                              />
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        showFirstLast={true}
                        onChange={page => setPage(page)}
                        current={page}
                        total={Math.ceil(data.count / 25)}
                    >
                        {" "}
                    </Pagination>
                </Collapsible>
            </Box>
        </div>
    )






}