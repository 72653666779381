import React from "react";
import {Picker} from "../Utils/Picker";


export default function WorkTypePicker({options, onSelect, dataFresh, work, disabled, showTitle=true, className, editable, setEditable, onSave, inlineEdit=true}){

    return (
        <Picker
            name={"work_type"}
            label={showTitle ? "Work Type" : null}
            value={work.work_type}
            blank={true}
            onChange={onSelect}
            options={options}
            optionField={"name"}
            disabled={disabled}
            className={className}
            inlineEdit={inlineEdit}
            editable={editable}
            setEditable={setEditable}
            onSave={onSave}
        />
    )



}