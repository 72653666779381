import React from "react";
import {useQuoteData} from "../../hooks/useQuoteData";
import Form from "./Form";
import {getDataFresh, getDepartmentsData} from "../../state/selectors";
import { useSelector } from "react-redux";
import { useDefaultsData } from "../../hooks/useDefaultsData";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import {useWorkTypeData} from "../../hooks/useWorkTypeData";

function Quote(props) {
  let dataFresh = useSelector(getDataFresh);
  let quote_loaded = useQuoteData(props.match.params.quote, dataFresh);
  let app_defaults_loaded = useDefaultsData({});
  let departmentsLoaded = useDepartmentsData({}, dataFresh);
  let workTypesLoaded = useWorkTypeData({}, dataFresh)
  let departments = useSelector(getDepartmentsData)
  return (
    <Form
      loaded={quote_loaded && app_defaults_loaded && departmentsLoaded && workTypesLoaded}
      endpoint={props.endpoint}
      history={props.history}
      departments={departments}
    />
  );
}

export default Quote;
