import React, {useState} from "react";
import {Box, Button, Columns, Form} from "react-bulma-components";
import Collapsible from "react-collapsible";
import useInput from "../../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import {getDataFresh} from "../../../state/selectors";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import usePostWorkType from "../../../hooks/usePostWorkType";


export default function NewWorkType(props){
    const dispatch = useDispatch();
    let [name, nameInput, setName] = useInput({
        type: "text",
        label: "Name",
    });
    let [warranty, setWarranty] = useState(false);
    let [approval, setApproval] = useState(false);
    const [, postWorkType] = usePostWorkType({
        name: name,
        warranty_work: warranty,
        approval_needed: approval
    })
    const onClear = () => {
        setName("");
        setWarranty(false);
        setApproval(false);
    }


    const onWarrantyCheck = e => {
        setWarranty(!warranty);
    };

    const onApprovalCheck = e => {
        setApproval(!approval);
    }
    const onSave = () => {
        postWorkType().then(() => (
            onClear()
        ))
    }

    return(
        <Box className="settings_type">
            <Collapsible
                className={"title"}
                trigger={"Add Work Type"}
                triggerOpenedClassName={"title"}
            >
                <Columns>
                    {nameInput}
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Warranty Work</Form.Label>
                            <Form.Checkbox
                                name={"warranty_work"}
                                checked={warranty}
                                onChange={onWarrantyCheck}
                            />
                        </Form.Field>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Field>
                            <Form.Label>Approval Needed</Form.Label>
                            <Form.Checkbox
                                name={"approval_needed"}
                                checked={approval}
                                onChange={onApprovalCheck}
                            />
                        </Form.Field>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            fullwidth
                            onClick={onSave}
                        >
                            Add
                        </Button>
                    </Columns.Column>
                </Columns>
            </Collapsible>
        </Box>
    )
}