import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction} from "../../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../../state/selectors";
import {NotificationManager} from "react-notifications";


export default function VehicleMakeRow({makeData, index, onUpdate, dataFresh}){
    const dispatch = useDispatch();
    const [make, setMake] = useState(makeData);
    const [editable, setEditable] = useState(false);

    const onEdit = e => {
        e.stopPropagation();
        setEditable(!editable);
    };

    const onChange = (e) => {
        let newVehicleMake = {...make};
        newVehicleMake[e.target.name] = e.target.value || null;
        setMake(newVehicleMake);
        onUpdate(index, newVehicleMake);
    }

    const submitUpdate = (e) => {
        e.stopPropagation();
        const conf = {
            method: "put",
            data: make,
            url: "api/vehiclemake/"+make.id
        }
        axios(conf).then(res => {
           setEditable(false);
           dispatch(createSetDataFreshAction(dataFresh + 1))
        })
    }

    const onDelete = () => {
        const conf = {
            method: "delete",
            url: "api/vehiclemake/"+make.id
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            if(err){
                NotificationManager.error("There was an error");
            }
        })
    }

    return (
        <tr>
            <td>
            <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"text"}
                            name={"name"}
                            value={make.name}
                            disabled={!editable}
                            onChange={onChange}
                        />
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                          <Button color="success" onClick={submitUpdate}>
                            Save
                          </Button>
                        </Form.Control>
                      ) : (
                        <Form.Control>
                          <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                      )}
                </Form.Field>
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onDelete}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    )

}