import React, {useState} from "react";
import { Button } from "react-bulma-components";
import axios from "axios";
import { Form } from "react-bulma-components";
import {
  updateCustomerInvoice,
  updateHireCustomerInvoice,
  updateWorkshopInvoice
} from "./updateInvoice";
import {readableDate, formatCurrency} from "../../utils";
import useSafeSave from "../../hooks/useSafeSave";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import FAIcon from "../Icon/FAIcon";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import {NotificationManager} from "react-notifications";
import WorkTypePicker from "../Job/WorkTypePicker";

export const PORows = ({
                         value,
                         onApprove,
                         printCheck,
                         selected,
                         endpoint,
                         refresh,
                         setInvoiceNo,
                         updateValue,
                         approvedView,
                         workTypes,
                         onWorkTypeChange
                       }) => {
  let rows = [];
  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();

  const onCheck = e => {
    e.stopPropagation();
    printCheck(value);
  };

  const onInvoiceChange = e => {
    makeDirty();
    setLastEdited(new Date());
    setInvoiceNo(e.target.value);
  };

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newWork = {...value};
    newWork[e.target.name] = e.target.value;
    updateValue(newWork);
  };

  const onApprovalReceived = (e) => {
      e.stopPropagation();
      let toUpdate = {
          target: {
              name: e.target.name,
              value: !value.approval_received
          }
      }
      onChange(toUpdate);
  }


  const onWorkSave = () => {
    let dataToSave = value;
    const conf = {
        data: dataToSave,
        method: "put",
        url: endpoint + "work/update/"+ value.id
    }
    axios(conf).then(res => {
        setEditable(false);
        refresh();
    }).catch(err => {
        NotificationManager.error("There was an error")
    })
  }
  const onSave = e => {
    return updateWorkshopInvoice(
      endpoint,
      value.job,
      value.job_workshop_invoice_number,
      value.job_workshop_payment_date || null
    ).then(() => {
      refresh();
      setEditable(!editable);
    });
  };

  const checkUpdated = () => {
    return axios
      .get(endpoint + "work/" + value.id)
      .then(
        work =>
          (new Date(work.data.last_saved).getTime() -
            new Date(value.last_saved).getTime()) /
          1000 <
          1
      );
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const firstRow = (
    <tr
      className="clickable table-highlight-orange small-row-white"
      onClick={() => window.open(`/editjob/${value.job}`, "_self")}
      key={value.id}
    >
      <td>{value.vehicle_registration}</td>
      <td>{value.workshop} </td>
      <td>{value.chargeable ? value.customer2 : value.customer} </td>
      <td>{readableDate(value.finish_date)}</td>
      <td>{value.description}</td>
      {useEditableTableTextAreaCell({
          propName: "invoice_comments",
          value: value.invoice_comments,
          onChange: onChange,
          onSave: onWorkSave,
          editable,
          setEditable,
          inlineEdit: true,
          disabled: value.po_approved
      })}
      <td>£{value.labour_cost.toFixed(2)}</td>
      <td onClick={e => e.stopPropagation()}>
          <WorkTypePicker
            className={"has-addons"}
            work={value}
            options={workTypes}
            onSelect={onWorkTypeChange}
            disabled={!editable}
            showTitle={false}
            editable={editable}
            setEditable={setEditable}
            onSave={onWorkSave}
          />
      </td>
      <td onClick={e => e.stopPropagation()}>
          <Form.Control>
              <Form.Checkbox
                checked={value.approval_received}
                name={"approval_received"}
                onChange={onApprovalReceived}
                disabled={!editable}
              />
          </Form.Control>
      </td>
      {useEditableTableTextCell({
        propName: "job_workshop_invoice_number",
        value: value.job_workshop_invoice_number,
        onChange: onInvoiceChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true,
        disabled: value.po_approved
      })}
      {useEditableTableDateCell({
        propName: "job_workshop_payment_date",
        value: value.job_workshop_payment_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true,
        disabled: value.po_approved
      })}
      <td></td>
      <td></td>
      <td>Parts Total:</td>
      <td>
        £{value.parts.reduce((total, part) => total + part.cost, 0).toFixed(2)}
      </td>
      {approvedView &&
      <td>
        {value.po_approved && value.po_approver ? `${value.po_approver.first_name} ${value.po_approver.last_name}` : ""}
      </td>}
      <td onClick={e => e.stopPropagation()}>
        <Form.Field>
          <Form.Control className="table-checkbox">
            <Form.Checkbox
              checked={selected}
              type="checkbox"
              onClick={onCheck}
              readOnly
            />
          </Form.Control>
        </Form.Field>
      </td>
      {!value.po_approved && (
        <td className="float-right">
          <Button
            color="success"
            onClick={e => {
              e.stopPropagation();
              onApprove(value);
            }}
          >
            <FAIcon size="small" icon={["fas", "check"]}/>
          </Button>
        </td>
      )}
    </tr>
  );

  rows.push(firstRow);

  const partRows = value.parts.map(part => {
    return (
      <tr key={part.id} className="small-row-black">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{part.name}</td>
        <td>{part.part_number}</td>
        <td>{part.supplier.display_name}</td>
        <td>{formatCurrency(part.cost)}</td>
        <td></td>
      </tr>
    );
  });

  rows = rows.concat(partRows);
  return rows;
};

export const InvoiceRows = ({
                              value,
                              onApprove,
                              printCheck,
                              selected,
                              endpoint,
                              refresh,
                              setInvoiceNo,
                              updateValue,
                              approvedView,
                              onReadyToApprove
                            }) => {
  const [editable, setEditable] = useState(false);
  const [commentsEditable, setCommentsEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();

  const onCheck = e => {
    e.stopPropagation();
    printCheck(value);
  };

  const onChangeInvoice = e => {
    makeDirty();
    setLastEdited(new Date());
    setInvoiceNo(e.target.value);
  };

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newWork = {...value};
    newWork[e.target.name] = e.target.value;
    updateValue(newWork);
  };

  const onSave = e => {
    if (value.chargeable) {
      return updateHireCustomerInvoice(
        endpoint,
        value.job,
        value.job_customer2_invoice_number
      ).then(() => {
        refresh();
        setEditable(!editable);
      });
    } else {
      return updateCustomerInvoice(
        endpoint,
        value.job,
        value.job_customer_invoice_number
      ).then(() => {
        refresh();
        setEditable(!editable);
      });
    }
  };

  const onSaveWork = e => {
    const conf = {
      method: "put",
      url: endpoint + 'work/update/' + value.id,
      data: value
    };
    return axios(conf).then(() => {
      refresh();
      setCommentsEditable(!commentsEditable)
    })
  };

  const checkUpdated = () => {
    return axios
      .get(endpoint + "work/" + value.id)
      .then(
        work =>
          (new Date(work.data.last_saved).getTime() -
            new Date(value.last_saved).getTime()) /
          1000 <
          1
      );
  };
  const [makeDirtyWork, safeSaveWork] = useSafeSave(
    onSaveWork,
    lastEdited,
    undefined,
    checkUpdated
  );

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  let rows = [];
  const firstRow = (
    <InvoiceFirstRow
      endpoint={endpoint}
      selected={selected}
      safeSave={safeSave}
      safeSaveWork={safeSaveWork}
      onChange={onChange}
      onChangeInvoice={onChangeInvoice}
      value={value}
      editable={editable}
      commentsEditable={commentsEditable}
      onApprove={onApprove}
      onCheck={onCheck}
      setEditable={setEditable}
      setCommentsEditable={setCommentsEditable}
      approvedView={approvedView}
      onReadyToApprove={onReadyToApprove}
    />
  );

  rows.push(firstRow);

  const partRows = value.parts.map(part => {
    return (
      <tr key={part.name + part.id} className="small-row-black">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{part.name}</td>
        <td>{part.part_number}</td>
        <td>{part.part_invoice_number}</td>
        <td>{part.supplier.display_name}</td>
        <td>{formatCurrency(part.customer_cost)}</td>
        <td></td>
      </tr>
    );
  });

  rows = rows.concat(partRows);
  return rows;
};

const InvoiceFirstRow = ({
                           value,
                           onChangeInvoice,
                           onChange,
                           safeSave,
                           safeSaveWork,
                           editable,
                           setEditable,
                           commentsEditable,
                           setCommentsEditable,
                           onCheck,
                           onApprove,
                           selected,
                           endpoint,
                           approvedView,
                           onReadyToApprove
                         }) => {
  // TODO: Shouldn't have to make a get request for each part here. The data should be in the value obj
  const [tableHighlight, setTableHighlight] = useState("");

  if (!value.quote && !tableHighlight) {
    setTableHighlight("table-highlight-orange");
  } else if (!tableHighlight) {
    let config = {
      url: endpoint + "worktotals/" + value.id
    };
    axios(config).then(result => {
      const costs = {...result.data};
      if (
        costs.total_estimate_labour_cost === costs.total_job_labour_cost &&
        costs.total_estimate_part_cost === costs.total_job_part_cost
      ) {
        setTableHighlight("table-highlight-orange");
      } else {
        setTableHighlight("table-highlight-red");
      }
    });
  }

  return (
    <tr
      className={`clickable ${tableHighlight} small-row-white`}
      onClick={() => window.open(`/editjob/${value.job}`, "_self")}
      key={value.id}
    >
      <td>{value.job}</td>
      <td>{value.vehicle_registration}</td>
      <td>{value.workshop}</td>
      <td>{value.chargeable ? value.customer2 : value.customer} </td>
      <td>{readableDate(value.finish_date)}</td>
      <td>{value.description}</td>
      <td>£{(value.customer_labour_cost || 0).toFixed(2)}</td>
      <td>{value.job_workshop_invoice_number}</td>
      {useEditableTableTextCell({
        propName: value.chargeable
          ? "job_customer2_invoice_number"
          : "job_customer_invoice_number",
        value: value.chargeable
          ? value.job_customer2_invoice_number
          : value.job_customer_invoice_number,
        onChange: onChangeInvoice,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true,
        disabled: value.invoice_approved
      })}
      <td></td>
      <td></td>
      <td></td>
      <td>Parts Total:</td>
      <td>
        £
        {value.parts
          .reduce((total, part) => total + part.customer_cost, 0)
          .toFixed(2)}
      </td>
      {useEditableTableTextAreaCell({
        propName: 'invoice_comments',
        value: value.invoice_comments,
        onChange,
        onSave: safeSaveWork,
        editable: commentsEditable,
        setEditable: setCommentsEditable,
        inlineEdit: true,
        disabled: value.invoice_approved
      })}
      {approvedView &&
      <td>
        {value.invoice_approved && value.invoice_approver ? `${value.invoice_approver.first_name} ${value.invoice_approver.last_name}` : ""}
      </td>}
      <td onClick={e => e.stopPropagation()}>
        <Form.Field>
          <Form.Control className="table-checkbox">
            <Form.Checkbox
              checked={selected}
              type="checkbox"
              name="selectedForPrint"
              onClick={onCheck}
              readOnly
            />
          </Form.Control>
        </Form.Field>
      </td>
      {!value.invoice_approved && value.ready_to_approve && (
        <td className="float-right">
          <Button
            color="success"
            // className="is-pulled-right"
            onClick={e => {
              e.stopPropagation();
              onApprove(value);
            }}
          >
            <FAIcon size="small" icon={["fas", "check"]}/>
          </Button>
        </td>
      )}
      {!value.invoice_approved && !value.ready_to_approve && (
        <td className="float-right">
          <Button
            color="info"
            // className="is-pulled-right"
            onClick={e => {
              e.stopPropagation();
              onReadyToApprove(value, false);
            }}
          >
            <FAIcon size="small" icon={["fas", "check"]}/>
          </Button>
        </td>
      )}
    </tr>
  );
};
