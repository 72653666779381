import React from "react";
import {Box, Table} from "react-bulma-components";
import NewWorkType from "./NewWorkType";
import Collapsible from "react-collapsible";
import {useDispatch, useSelector} from "react-redux";
import {useWorkTypeData} from "../../../hooks/useWorkTypeData";
import {getDataFresh, getWorkTypeData} from "../../../state/selectors";
import Spinner from "react-spinner-material";
import WorkTypeRow from "./WorkTypeRow";
import axios from "axios";
import {
    createSetDataFreshAction,
    createSetWorkTypeDataAction
} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";


export default function WorkTypeView(props){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);
    let loading = useWorkTypeData({}, dataFresh);
    let data = useSelector(getWorkTypeData);



    const onWorkTypeUpdate = (index, newWorkType) => {
        let newState = data;
        newState.results[index] = newWorkType;
        dispatch(createSetWorkTypeDataAction(newState));
    }

    if(!loading){
        return(
            <Box>
                <Spinner
                  className="spinner-centre"
                  size={120}
                  spinnerColor={"#3273dc"}
                  spinnerWidth={2}
                />
            </Box>
        );
    }


    return (
        <div>
            <NewWorkType/>
            <Box>
                <Collapsible
                    trigger={"Work Types"}
                    className={"title"}
                    triggerOpenedClassName={"title"}
                >
                    <Table
                        size={"fullwidth"}
                    >
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Warranty Work</th>
                                <th>Approval Needed</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.results.map((workType, index) => (
                                <WorkTypeRow
                                    key={workType.id}
                                    workTypeData={workType}
                                    index={index}
                                    dataFresh={dataFresh}
                                    onUpdate={onWorkTypeUpdate}
                                />
                            ))}
                        </tbody>
                    </Table>
                </Collapsible>
            </Box>
        </div>
    )



}