import React, {useState} from "react";
import {useVehicleMakeData} from "../../../../hooks/useVehicleMakeData";
import {getDataFresh, getVehicleMakeData} from "../../../../state/selectors";
import {useDispatch, useSelector} from "react-redux";
import {Box, Heading, Pagination, Table} from "react-bulma-components";
import NewVehicleMake from "./NewVehicleMake";
import VehicleMakeRow from "./VehicleMakeRow";
import Spinner from "react-spinner-material";
import {createSetVehicleMakeDataAction} from "../../../../actions/dataActions";
import Collapsible from "react-collapsible";


export default function VehicleMake(props){
    const [page, setPage] = useState(1)
    const dispatch = useDispatch();
    let params = {page: page}
    const data = useSelector(getVehicleMakeData)

    const onVehicleMakeUpdate = (index, newVehicleMake) => {
        let newState = data;
        newState.results[index] = newVehicleMake;
        dispatch(createSetVehicleMakeDataAction(newState))
    }

    return (
        <div>
            <NewVehicleMake />
            <Box>
                <Collapsible
                    className="title"
                    triggerOpenedClassName="title"
                    trigger="Vehicle Makes"
                >
                    <Heading size={6}>Note: Changing these will affect all vehicles of that make</Heading>
                    <Table
                        size={"fullwidth"}
                    >
                        <thead>
                            <tr>
                                <th>Make</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.results.map((make, index) => (
                              <VehicleMakeRow
                                makeData={make}
                                index={index}
                                onUpdate={onVehicleMakeUpdate}
                                dataFresh={props.dataFresh}
                              />
                            ))}
                        </tbody>
                    </Table>
                </Collapsible>
            </Box>
        </div>
    )






}